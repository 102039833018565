import Http from "./axios.js";
import store from "../store";
import QS from "qs";
      
 
const ajaxUrls = "http://luoboapiv1.udao.sh.cn"
// const ajaxUrls = "https://api.luobolieshou.com"
export default {
  json(url, data, config) {
    return Http.get(jsonUrl + url, data, config);
  },

  //陈水林请求post方法的封装
  Apost(url, data, config) {
    console.log(url)
    url = ajaxUrls + url;
    var param = {
      accessToken:"qsg9RXOhAwzpWpOfc",
      user_id:"45880",
      token:"3f328148a143929335ed6d28d594dbd7",
      requestParams: data
    };
     
    return Http.post(url, QS.stringify(param), config);
  },

  upload(url, data) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
    return Http.post(ajaxUrls + url, data, config);
  },

  
};
